import { StyledSubtitle, withAuthLayout } from '@/components/auth/AuthLayout'
import UtilButton from '@/components/chat/ChatPane/UtilButton'
import { saveLoginTokenToCookie } from '@/components/utils/Auth'
import { useLoggedInUser } from '@/components/utils/LoggedInUserProvider'
import useDetectOldBrowser from '@/components/utils/useDetectOldBrowser'
import themes from '@/data/themes'
import { AuthClient } from '@/types/index'
import FondasiAPI from '@/utils/FondasiAPI'
import FondasiAPIv3 from '@/utils/FondasiAPIv3'
import useLogin from '@/utils/hooks/v3/auth/useLogin'
import useWorkspaceExists from '@/utils/hooks/v3/workspace/useWorkspaceExists'
import useWorkspaces from '@/utils/hooks/v3/workspace/useWorkspaces'
import { CircularProgress, Link, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { NextPage } from 'next'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import getEnv, { CLIENT, GRANT_PASSWORD_CLIENT_ID } from '../../utils/getEnv'

interface LoginForm extends FieldValues {
  email: string,
  password: string
}

const LoginPage: NextPage = () => {
  const router = useRouter()
  const { r, error: errorFromParam } = router.query
  const { control, handleSubmit, reset, setValue } = useForm<LoginForm>()
  const { ErrorOldBrowser } = useDetectOldBrowser()
  const { loggedInData, loggingIn, errorLogin, login } = useLogin()
  const [grantType, _] = useState<AuthClient>('password')
  const [error, setError] = useState<string|undefined>(undefined)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const [isAuthenticatedWorkspace, setIsAuthenticatedWorkspace] = useState<boolean>(true)
  const [_loggedInUser, setLoggedInUser] = useLoggedInUser()
  const { checkWorkspaceExists } = useWorkspaceExists()
  const { getWorkspaces, errorGetWorkspaces } = useWorkspaces()

  useEffect(() => {
    if (router.query.email) {
      setValue('email', router.query.email as string)
    }
  }, [])
  useEffect(() => {
    if (errorLogin) reset()
  }, [errorLogin])

  // validation redirect workspace login
  useEffect(() => {
    let cancelEffect = false

    const checkWorkspace = async (slug) => {

      try {
        const workspace = await checkWorkspaceExists(slug)
        if (workspace.data.workspace.slug !== r) {
          Router.replace('/login', undefined, { shallow: true })
          // Router.push('/login')
        }
      } catch (e) {
        Router.replace('/login', undefined, { shallow: true })
        // Router.push('/login')
      }
    }

    if (!cancelEffect) {
      if (r) checkWorkspace(r)
    }

    return () => {
      // setIsValidWorkspace(true)
      cancelEffect = true
    }
  }, [r])

  useEffect(() => {
    if (errorFromParam) setError(decodeURIComponent(errorFromParam as string))
  }, [errorFromParam])

  useEffect(() => {
    let cancelEffect = false

    const setWorkspaceUser = async (loggedInData) => {
      setLoggedInUser({ ...loggedInData?.globalUser })
      saveLoginTokenToCookie(loggedInData.jwt.token)

      const token = router.query.token
      if (token) { // is user login with invitation token
        router.replace({
          pathname: '/accept-invite',
          query: { token }
        })
      } else if (!loggedInData?.globalUser?.isVerified) { // is user not verified???
        // NOT YET!!
        Router.push('/signup/success')
      } else { // is user verified???
        // YES!! YES!! YES!!

        if (r){
          FondasiAPIv3.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v3`, r)
          FondasiAPI.setBaseURL(`${getEnv['fondasiAPIBaseURL']}/v2/${r}`)

          const getUserDetails = await FondasiAPI.getUserDetails()
          if (getUserDetails.status !== 200) {
            setIsAuthenticatedWorkspace(false)
            setIsChecking(false)
            return
          }

          await Router.push('/[application]/home', `/${r}/home`)
        } else {
          const workspaces = await getWorkspaces()
          if (workspaces.status === 200) {
            if (workspaces.data.workspaces.length === 0) {
              router.push('/workspaces/create')
            } else if (workspaces.data.workspaces.length === 1){
              router.push(`/${workspaces.data.workspaces[0].slug}/home`)
            } else {
              router.push('/workspaces')
            }
          }else {
            setError(errorGetWorkspaces?.error)
          }
        }
      }

    }

    if (!cancelEffect) {
      if (loggedInData){
        setIsChecking(true)
        setWorkspaceUser(loggedInData)
      }
    }

    return () => {
      setIsChecking(false)
      // setIsValidWorkspace(true)
      cancelEffect = true
    }

  }, [loggedInData])

  const onSubmit: SubmitHandler<LoginForm> = async (data: LoginForm, event: React.BaseSyntheticEvent | undefined) => {
    event?.preventDefault()
    let invitation: any | undefined = undefined
    if (router.query.token) {
      invitation = {
        token: router.query.token
      }
    }
    try {
      await login({ ...data, clientId: GRANT_PASSWORD_CLIENT_ID, grantType }, invitation)
    } catch (_e) {
      // do nothing
    }
  }

  return (
    <>
      <Head>
        <title>Login | {themes[CLIENT]?.head?.text || 'Fondasi'}</title>
      </Head>
      <GreetingText>
        <h2 style={{ margin: '0px' }}>Welcome</h2>
        <StyledSubtitle style={{ margin: '0px' }}>Log In to your account to continue</StyledSubtitle>
      </GreetingText>
      {ErrorOldBrowser}
      {
        errorLogin ?
          <Alert severity='error'>{errorLogin.error}</Alert> :
          null
      }
      {
        error ?
          <Alert severity='error'>{error}</Alert> :
          null
      }
      <>
        {!isAuthenticatedWorkspace ? <Alert severity="error">You do not have access to workspace '{r}'</Alert> : null}
        <form id="login" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={'email'}
            rules={{ required: 'Email is required' }}
            render={({ field }) => {
              return <TextField
                autoComplete='email'
                variant="outlined" margin="dense" required
                disabled={loggingIn || isChecking} label="Email" type="email"
                onChange={field.onChange} value={field.value || ''} fullWidth />
            }}
          />
          <Controller
            control={control}
            name={'password'}
            rules={{ required: 'Password is required' }}
            render={({ field }) => {
              return <TextField
                autoComplete='current-password'
                variant="outlined" margin="dense" required
                disabled={loggingIn || isChecking} label="Password" type="password"
                onChange={field.onChange} value={field.value || ''} fullWidth />
            }}
          />
          <AdditionalForm>
            {/* <Controller
              name="rememberMe"
              control={control}
              render={({ field: controlField }) => {
                return <>
                  <FormControlLabel control={<Checkbox {...controlField}
                    onChange={(e) => {
                      controlField.onChange(e.target.checked)
                      return e.target.checked
                    }}
                    color="primary"
                    disabled={loggingIn || isChecking}
                  />}
                  label={<Typography>Remember me</Typography>} />
                </>
              }}
            /> */}
            <div />
            <Link href="/auth/forgot-password"><p>Forgot Password ?</p></Link>
          </AdditionalForm>
          <UtilButton fullWidth variant="contained" color='primary' type="submit" disabled={loggingIn || isChecking}>
            {
              loggingIn  || isChecking&& <CircularProgress size={20} style={{ color: '#FFF' }} />
            }
            &nbsp;
            <span>
              {
                loggingIn || isChecking ? 'Logging you in...' : 'LOG IN'
              }
            </span>
          </UtilButton>
          <LoginLink>
            <p>Don't have an account? &nbsp;</p><Link href="/signup"><Typography color="primary">Sign Up</Typography></Link>
          </LoginLink>
        </form>
      </>
    </>
  )
}

export default withAuthLayout(LoginPage)

const GreetingText = styled.div`
justify-self: flex-start;
align-content: center;
margin-bottom: 16px;
`

const LoginLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Trueno';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 8px;
  .MuiTypography-root {
    font-size: inherit;
    cursor: pointer;
    font-weight: bold;
  }
`


const AdditionalForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Trueno';
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 8px;
  .MuiTypography-root {
    font-size: inherit;
    cursor: pointer;
    color: black;
  }
`

