import { AxiosPromise } from 'axios'
import { Dispatch } from 'react'
import { FondasiError, useApi } from '../../useFondasiFetch'

export type ResponseObject = {
  workspace: {
    title: string,
    client: string | null,
    slug: string
  }
}

type Result = {
  workspaceExists?: ResponseObject | undefined,
  checking?: boolean,
  setError: Dispatch<any>,
  errorWorkspaceExists?: FondasiError,
  checkWorkspaceExists: (slug: string) => AxiosPromise<ResponseObject>
}

export default function useWorkspaceExists(): Result {
  const { data, error, isValidating, call3, setError } = useApi<ResponseObject>()

  return {
    workspaceExists: data,
    checking: isValidating,
    errorWorkspaceExists: error,
    setError,
    checkWorkspaceExists: (slug: string) => {
      return call3({
        url: `/workspace/${slug}/check`,
        method: 'get'
      })
    }
  }
}