import { Workspace } from '@/types/index'
import { AxiosPromise } from 'axios'
import { FondasiError, useApi } from '../../useFondasiFetch'

export type ResponseObject = {
  workspaces: Workspace[]
}

type Result = {
  workspaces?: ResponseObject | undefined,
  gettingWorkspaces?: boolean,
  errorGetWorkspaces?: FondasiError,
  getWorkspaces: () => AxiosPromise<ResponseObject>
}

export default function useWorkspaces(): Result {
  const { data, error, isValidating, call3 } = useApi<ResponseObject>()

  return {
    workspaces: data,
    gettingWorkspaces: isValidating,
    errorGetWorkspaces: error,
    getWorkspaces: () => {
      return call3({
        url: '/workspaces',
        method: 'get'
      })
    }
  }
}