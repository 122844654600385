import { forwardRef, RefObject, ForwardRefExoticComponent, CSSProperties } from 'react'
import { Button, ButtonProps, CircularProgress, withStyles } from '@material-ui/core'
import { CLIENT } from '@/utils/getEnv'

const UtilButtonComponent = withStyles({
  root: {
    '& MuiButton-root': {
      color: '#414141',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    padding: '0',
    textTransform: 'none'
  },
  label: {
    padding: '8px 16px',
    lineHeight: 'normal'
  }
})(Button)

const UtilButtonComponentSmartfren = withStyles({
  root: {
    padding: '0px',
    textTransform: 'none',
  },
  label: {
    padding: '4px 8px'
  },
  outlinedSecondary: { // button ternary
    borderColor: '#D9D9D9 !important',
    color: '#262626 !important',
    '&:disabled, &:disabled *': {
      background: 'white !important',
      color: '#D9D9D9 !important'
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important'
    },
    '&:active, &:visited': {
      background: '#E9E9E9 !important',
    }
  },
  containedSecondary: { // button text
    color: '#262626 !important',
    background: 'white !important',
    border: '0 !important',
    '&:hover': {
      background: '#F5F5F5 !important'
    },
    '&:disabled, &:disabled *': {
      background: 'white !important',
      color: '#D9D9D9 !important'
    },
    '&:active, &:visited': {
      background: '#E9E9E9 !important',
    }
  },
  containedPrimary: { // button primary
    '&:disabled, &:disabled *': {
      backgroundColor: '#FFE5EB !important',
      color: 'white !important'
    },
    '&:hover': {
      backgroundColor: '#FF3D71 !important',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5) !important',
      color: 'white !important'
    },
    '&:active, &:visited': {
      background: '#D80453 !important'
    }
  },
  outlinedPrimary: { // button secondary
    borderColor: '#FF1659 !important',
    color: '#FF1659 !important',
    '&:disabled, &:disabled *': {
      borderColor: '#FFBCCD !important',
      backgroundColor: 'white !important',
      opacity: '0.8 !important',
      color: '#FFBCCD !important'
    },
    '&:hover': {
      backgroundColor: '#FFE5EB !important',
      borderColor: '#FF1659 !important',
      color: '#FF1659 !important'
    },
    '&:active, &:visited': {
      background: '#FFBCCD !important',
      borderColor: '#FF1659 !important',
    }
  },
})(Button)

const themesButton = {
  smartfren: UtilButtonComponentSmartfren
}

const UtilButton: ForwardRefExoticComponent<ButtonProps & { loading?: boolean, style?: CSSProperties }> = forwardRef(({ loading, children, ...props }, ref: ((instance: HTMLButtonElement | null) => void) | RefObject<HTMLButtonElement> | null | undefined) => {
  const ButtonComponent = themesButton[CLIENT] || UtilButtonComponent
  return (
    <ButtonComponent {...props} ref={ref}>
      { loading ? <><CircularProgress size={20} />&nbsp;</> : null }
      {children}
    </ButtonComponent>
  )
})

export default UtilButton