import themes from '@/data/themes'
import { CLIENT } from '@/utils/getEnv'
import Alert from '@material-ui/lab/Alert'
import { ReactNode, useEffect, useState } from 'react'
import { browserName, browserVersion } from 'react-device-detect'

interface DetectOldBrowser {
  ErrorOldBrowser?: ReactNode
}

const mappingMinimalVersionOfBrowser = {
  'Chrome Headless': {
    minVersion: 80
  },
  Chrome: {
    minVersion: 80
  },
  Firefox: {
    minVersion: 75
  },
  Mozzila: {
    minVersion: 75
  },
  Edge: {
    minVersion: 80
  },
  Safari: {
    minVersion: 6 // minimal version that support WebSocket API for socket.io-client
  }
}

export default function useDetectOldBrowser(currentClient?: 'smartfren'): DetectOldBrowser {
  const [isOldBrowser, setIsOldBrowser] = useState(false)
  const currThemeHead = themes[CLIENT || currentClient]?.head
  const title = currThemeHead?.text

  useEffect(() => {
    setIsOldBrowser(isBrowserOld())
  }, [browserName, browserVersion])

  const isBrowserOld = () => {
    console.log('browser version ',browserName, browserVersion)
    const minVersion = mappingMinimalVersionOfBrowser[browserName]?.minVersion
    return !(minVersion && parseInt(browserVersion) >= minVersion)
  }

  return {
    ErrorOldBrowser: isOldBrowser ?
      <Alert style={{ textAlign: 'left' }} severity="error">{title || 'Fondasi'} is not fully supported to run in this browser version {`(${browserVersion})`}. <br />Please use the <b>latest version</b> of {browserName} browser.</Alert>
      : undefined
  }

}